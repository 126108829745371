import React, { useEffect } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import * as prismicH from "@prismicio/helpers";


const Faq = () => {

    const [question, questionState] = useAllPrismicDocumentsByType('questions');
    const notFound = questionState.state === 'failed';

    useEffect(() => {
        if (!questionState.state === 'failed') {
            console.warn("Wasn't able to find questions, check your repository.");
        }
    },[questionState.state])

    if (question) {

        return (
            <div id="faq">
                <div className="container-md">
                    <div className="faqWrapper">
                        <div className="faqHeading">
                            <h1>Frequently Asked Questions</h1>
                        </div>
                        <div className="faqSection">
                            {question.map((q, idx) =>
                                <div key={idx}>
                                    <h4 className="faqQuestion">{idx + 1}. {prismicH.asText(q.data.question)}</h4>
                                    <p className="faqAnswer">{prismicH.asText(q.data.answer)}</p>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        )
    } else if (notFound) {
        return <div></div>
    }
    return null;
}

export default Faq;
