import React, { useEffect } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import * as prismicH from "@prismicio/helpers";

import Image from '../Components/Image';

const Gallery = () => {

    const [image, imageState] = useAllPrismicDocumentsByType('gallery');
    const notFound = imageState.state === 'failed';


    useEffect(() => {
        if (!imageState.state === 'failed') {
            console.warn("Wasn't able to find an image, check your repository.");
        }
    },[imageState.state])

    if (image) {

        console.log(image)

        return (
            <div className="gallery">
                <div className="container-md">
                    <div className="row">
                        {image.map((i, idx) =>
                            <div className="col-md-4 col-sm-12" key={idx}>
                                <img src={i.data.image.url} className="d-block w-50" alt="..." />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    } else if (notFound) {
        return <div></div>
    }

    return null;
}

export default Gallery;
