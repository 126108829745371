import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSinglePrismicDocument } from '@prismicio/react';

import * as prismicH from "@prismicio/helpers";
import Image from '../Assets/town2.svg';
import Image2 from '../Assets/house.jpeg';

const Landing = () => {

    const [info, infoState] = useSinglePrismicDocument('landing');
    const notFound = infoState.state === 'failed';

    useEffect(() => {
        if (!infoState.state === 'failed') {
            console.warn("Landing wasn't found, check your repository.")
        }

    },[infoState.state]);


    if (info) {

        const title = prismicH.asText(info.data.title);
        const caption = prismicH.asText(info.data.caption);

        return (
            <div id="landing" style={{backgroundImage: `url(${Image2})` }}>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-it ">
                        <div className="carousel-capt">
                        <Container>
                            <Row>
                                <Col md={12} sm={12}>
                                    <h1 className="landingTitle">{title}</h1>
                                    <p className="landingCaption">{caption}</p>
                                </Col>
                            </Row>
                        </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    } else if (notFound) {
        return <div></div>
    }

    return null;


};

export default Landing;
