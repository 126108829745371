import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const Contact = () => {
	return (
		<div id="contact">
			<Container fluid>

			<h4 className="contactTitle">Contact</h4>
			<span className="divider"> <hr className="contactDivider"/> </span>
			<p className="contactCaption">Let's get in touch! Please fill out the form and we'll get back to you as quickly as possible!</p>
					<form className="contactForm" action="https://formsubmit.co/westburysoftwash@gmail.com" method="POST">
						<Row>
							<Col md={12}>
								<div className="mb-3">
									<Row>
										<Col md={6} sm={12}>
										    <label htmlFor="firstName" className="form-label">First Name</label>
										    <input type="text" name="first-name" className="form-control" id="firstName" aria-describedby="firstName" />
										</Col>
										<Col md={6} sm={12}>
											<label htmlFor="lastName" className="form-label">Last Name</label>
										    <input type="text" name="last-name" className="form-control" id="lastName" aria-describedby="lastName" />
										</Col>
									</Row>
							  	</div>
							  	<Col md={12} sm={12}>
							  		<div className="mb-3">
								  		<label htmlFor="formEmail" className="form-label">Email Address</label>
										<input type="email" name="email" className="form-control" id="formEmail" aria-describedby="emailHelp" />
										<div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
							  		</div>
							  	</Col>
							  	<Col md={12}>
							  		<div className="mb-3">
								  		<label htmlFor="propertyType" className="form-label">Property Type</label>
										<input type="text" name="property-type" className="form-control" id="propertyType" aria-describedby="propertyType" />
							  		</div>
							  	</Col>
							  	<Col md={12}>
							  		<div className="mb-3">
								    	<label htmlFor="textAreaForm" className="form-label">Inquiry</label>
								    	<textarea className="form-control" id="textAreaForm" name="inquiry" rows="3"/>
								    	<div id="textArea" className="form-text">Let us know any concerns or interest you have!</div>
							  		</div>
							  	</Col>


							  	<button type="submit" className="btn btn-primary">Submit</button>
							</Col>
						</Row>
					</form>
			</Container>
		</div>
	)
}

export default Contact;
