import React from 'react';
import { Routes , Route } from 'react-router-dom';

import './Utils/Global.css';
import Navbar from './Utils/Navbar';
import Footer from './Utils/Footer';
import Home from './Pages/Home';
import Admin from './Pages/Admin';
import Gallery from './Pages/Gallery';

const App = () => {
    return (
        <div className="App">
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/admin" element={<Admin />} />
                <Route exact path="/gallery" element={<Gallery />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
