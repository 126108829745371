import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTwitterSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div id="footer">
			<Container>
				<Row>
					<Col md={9}>
						<h3 className="footerCompany">Westbury Softwash</h3>
						<h6 className="footerTag">Developed by <a href="https://instagram.com/westburysoftwash" target="_blank" className="footerLink">Nero Development</a></h6>
						<p className="footerCompanyTag"> Westbury Softwash &copy; 2022  All Rights Reserved.</p>
					</Col>
					<Col md={3}>
						<Row>
							<div className="footerIconSection">
								<a href="/"> <FaTwitterSquare className="footerIcons" size={32} /> </a>
								<a href="/"> <FaFacebookSquare className="footerIcons" size={32} /> </a>
								<a href="https://instagram.com/westburysoftwash" target="_blank"> <FaInstagramSquare className="footerIcons" size={32} /> </a>
							</div>
						</Row>

					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Footer;
