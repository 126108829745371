import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import * as prismicH from "@prismicio/helpers";


const Cards = (props) => {

	const [cards, cardState] = useAllPrismicDocumentsByType('services');
	const notFound = cardState.state === 'failed';

	useEffect(() => {
		if(!cardState.state === 'failed') {
			console.warn("Wasn't able to find services, check your repository.")
		}
	},[cardState.state])

	if (cards) {


		return (
			<div id="cards">

			<div className="cardSection">
				<Row>
					{cards.map((i, idx) =>
						<Col md={4} sm={12} key={idx}>
							<Container>
								<img className="cardImage" src={i.data.image.url} alt="Picture for Cards" />
								<h3 className="cardTitle">{prismicH.asText(i.data.service)}</h3>
								<p className="cardCaption">{prismicH.asText(i.data.description)}</p>
							</Container>
						</Col>
					)}
				</Row>
			</div>

			</div>
		)

	} else if (notFound) {
		return <div></div>
	}

	return null;
}

export default Cards;
