import React from 'react';

import '../Utils/Home.css';

import Landing from '../Components/Landing';
import About from '../Components/About';
import Services from '../Components/Services';
import Contact from '../Components/Contact';
import Faq from '../Components/Faq';

const Home = () => {
	return (
		<div>
			<Landing />
			<About />
			<Services />
			<Faq />
			<Contact />
		</div>
	)
}

export default Home;
