import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PrismicProvider } from '@prismicio/react';
import { client } from './Prismic';


ReactDOM.render(
  <BrowserRouter>
    <PrismicProvider client={client}>
        <App />
    </PrismicProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
