import React, {useEffect } from 'react';
import { useSinglePrismicDocument } from '@prismicio/react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import * as prismicH from "@prismicio/helpers";

import Cards from './Cards';




const Services = () =>  {
	const [service, serviceState] = useSinglePrismicDocument('service');
	const notFound = serviceState.state === 'failed';

	useEffect(() => {

		if (!serviceState.state === 'failed') {
			console.warn("Wasn't able to find service, check your repository.");
		}

	},[serviceState.state]);


	if (service) {

		const caption = prismicH.asText(service.data.caption);

		return (
			<div id="services">
				<div className="container-md">
					<div className="serviceSection">

					<div className="serviceHeading">
						<h4 className="serviceTitle">Services</h4>
						<span className="divider"><hr /></span>
						<p className="serviceCaption">{caption}</p>
					</div>

							<div className="cardCont">
								<Cards />
							</div>

							<div className="serviceButton">
								<a className="btn btn-primary mt-4" href="/gallery">View Gallery</a>
							</div>




					</div>
				</div>
			</div>
		)
	} else if (notFound) {
		return <div></div>
	}

	return null;

}

export default Services;
