import React from 'react';
import Logo from '../Assets/logo.png';

const Navbar = () => {
	return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
  		<div className="container-md">
    		<a className="navbar-brand" href="/"><img className="" alt="" src={Logo} /></a>
    		<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      			<span className="navbar-toggler-icon"></span>
    		</button>
    		<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    			<div className="navbar-nav">
        			<a className="nav-link active" aria-current="page" href="/">Home</a>
		        	<a className="nav-link" href="#about">About</a>
		        	<a className="nav-link" href="#services">Services</a>
		        	<button className="nav-link btn btn-sm btn-outline-secondary" type="button">Contact</button>
      		</div>
    	</div>
  	</div>
	</nav>
	)
}

export default Navbar;
