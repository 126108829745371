import React, { useEffect } from 'react';

const Admin = () => {

    useEffect( () => {
        window.location.href="https://westburysw.herokuapp.com/admin"
    }, [])

    return (
        <div>
        </div>
    )
}
export default Admin;
