import React, { useEffect } from 'react';
import { useSinglePrismicDocument } from '@prismicio/react';
import * as prismicH from "@prismicio/helpers";
import { Container, Col } from 'react-bootstrap';


const About = () => {

	const [about, aboutState] = useSinglePrismicDocument('about');
	const notFound = aboutState.state === 'failed';

	useEffect(() => {
		if (!aboutState.state === 'failed') {
			console.warn("Wasn't able to find about, check your repository.");
		}

    },[aboutState.state]);

	if (about) {
		const caption = prismicH.asText(about.data.caption);
		return (
		    <div id="about">
		    	<Container>
		    		<Col md={12}>
			    		<h4 className="aboutTitle">About</h4>
			    		<span className="divider"><hr /></span>
			    		<p className="aboutCaption">{caption}</p>
		    		</Col>
		    	</Container>
		    </div>
		)
	} else if (notFound) {
		return <div></div>
	}
	return null;
}

export default About;
